import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { articleFiles } from "./articles";

// Function to create SEO-friendly URL slugs
const createSlug = (title) => title.toLowerCase().replace(/\s+/g, "-");

export default function Blog() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    async function fetchArticles() {
      const loadedArticles = [];

      for (const file of articleFiles) {
        const module = await import(`./articles/${file}.jsx`);
        loadedArticles.push(module.metadata);
      }

      setArticles(loadedArticles);
    }

    fetchArticles();
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8 mt-4 mr-4">
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-bold mb-4">Blog</h1>
        <ul>
          {articles.map(({ id, title, author, date }) => (
            <li key={id} className="mb-2">
              <Link
                to={`/blog/${id}/${createSlug(title)}`}
                className="text-[#8e2421] hover:text-[#8e252198]"
              >
                #{id} - {title}
              </Link>
              <p className="text-sm text-gray-500">
                By {author} - Published on {date}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
