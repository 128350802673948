const moment = require("moment");

export const utils = {
  currencies: { GBP: "£", EUR: "€", USD: "$" },
  normalizeCurrency: (currency) => {
    const normalizedCurrency = currency.toUpperCase();
    return utils.currencies[normalizedCurrency] || currency;
  },
  formatDate: (date, format_to) => {
    return moment(date).format(format_to);
  },
};
