import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function Article() {
  const { articleId } = useParams(); // We only use articleId, ignoring the title part
  const navigate = useNavigate();
  const [ArticleComponent, setArticleComponent] = useState(null);

  useEffect(() => {
    import(`./articles/Article${articleId}.jsx`)
      .then((module) => setArticleComponent(() => module.default))
      .catch(() => setArticleComponent(() => () => <p>Article not found</p>));
  }, [articleId]);

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-32 mt-4 mr-4">
      <button
        onClick={() => navigate("/blog")}
        className="mb-4 px-4 py-2 bg-[#8e2421] text-white rounded-lg shadow-md hover:bg-[#8e252198]"
      >
        Back to Blog
      </button>
      {ArticleComponent ? <ArticleComponent /> : <p>Loading...</p>}
    </div>
  );
}
