import React from "react";
import { Link } from "react-router-dom";

const buttonClass =
  "bg-[#8e2421] text-white py-1 px-2 md:px-4 md:mx-4 rounded-md font-extrabold transition duration-300 ease-in-out transform hover:scale-105";

export default function Footer() {
  return (
    <div className="fixed bottom-0 bg-[#e6e4e4] w-screen z-10 text-sm md:text-lg">
      <div className="mx-auto px-4 py-2 flex flex-col justify-between items-center">
        <div className="flex items-center justify-center space-x-2 md:space-x-4 mb-2 md:mb-0">
          <Link className={buttonClass} to="/contact-us">
            Contact Us
          </Link>
          <Link
            className={buttonClass}
            to="https://www.linkedin.com/company/werewolf-solutions/"
            target="_blank"
          >
            LinkedIn
          </Link>
          <Link
            className={buttonClass}
            to="https://discord.gg/YfkW23nfUG"
            target="_blank"
          >
            Discord
          </Link>
          <Link
            className={buttonClass}
            to="https://t.me/+IgwxaWzM-U9kUsG_"
            target="_blank"
          >
            Telegram
          </Link>
        </div>
        <p className="text-center mt-2">
          &copy; {new Date().getFullYear()}{" "}
          <span className="text-[#8e2421] font-extrabold">
            Werewolf Solutions
          </span>
          . All rights reserved.
        </p>
      </div>
    </div>
  );
}
